import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import HomeHero from '../components/HomeHero';
import Contact from '../components/Contact';
import HomeTestimonials from '../components/HomeTestimonials';
import HomePlatform from '../components/HomePlatform';
import HomeClients from '../components/HomeClients';

const indexQuery = graphql`
  {
    platform: allDatoCmsPlatformPage(sort: { fields: title, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          homepageText
          homepageButtonTitle
        }
      }
    }
    page: datoCmsHome {
      heroTitle
      heroSubtitle
      heroButtonText
      heroImage {
        fluid(maxWidth: 900, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      aboutHeading
      aboutSubheading
      aboutCopy
      clients {
        url
      }
      partners {
        id
        image {
          fluid(maxWidth: 500, imgixParams: { fm: "png", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        body
      }
      testimonials {
        id
        name
        title
        body
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default function IndexPage() {
  const data = useStaticQuery(indexQuery);
  const {
    heroTitle,
    heroImage,
    heroSubtitle,
    heroButtonText,
    seoMetaTags,
    aboutHeading,
    aboutSubheading,
    aboutCopy,
    clients,
    partners,
    testimonials,
  } = data.page;

  const { edges: platformEdges } = data.platform;

  const homeHeroProps = {
    heroTitle,
    heroImage,
    heroSubtitle,
    heroButtonText,
    aboutHeading,
    aboutSubheading,
    aboutCopy,
  };

  const homePlatformProps = {
    platformFeatures: platformEdges.map((p) => p.node),
  };

  const homeClientProps = {
    clients,
    partners,
  };
  const testProps = {
    testimonials,
  };
  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <HomeHero {...homeHeroProps} />
      <HomePlatform {...homePlatformProps} />
      <HomeClients {...homeClientProps} />
      <HomeTestimonials {...testProps} />
      <Contact />
    </Fragment>
  );
}
