import React from 'react';
import styled from 'styled-components';
import map from 'lodash/map';

import { homePlatformTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import SectionHeading from './SectionHeading';
import { font } from '../consts/style';
import { Link } from 'gatsby';

import RedLine from '../images/art-red-black-line-long.svg';

// bump

const Wrapper = styled.div`
  padding-top: 12rem;
  margin-bottom: 12rem;
  text-align: center;

  .features {
    padding-top: 2.4rem;
    justify-content: center;

    .feature {
      padding: 0 2.4rem;
      margin-bottom: 4rem;
      position: relative;
    }
    h3 {
      margin-bottom: 2rem;
      position: relative;
      z-index: 10;
      ${font.h4};
    }
    p {
      margin-bottom: 2.4rem;
      position: relative;
      z-index: 10;
    }
    a {
      position: relative;
      z-index: 10;
      button {
        ${font.buttonInverse}
      }
    }
  }
`;

const R = styled.img.attrs({ src: RedLine })`
  position: absolute;
  height: 24rem;
  top: 0;
  left: 50%;
  transform: rotate(45deg) translate(-8rem, -8rem);
  z-index: 0;
`;

export default function HomePlatform({ platformFeatures }) {
  function renderPlatformFeatures() {
    return map(platformFeatures, (p) => (
      <div key={p.id} className="feature col-md-4">
        <R />
        <h3>{p.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: p.homepageText }}></div>
        <Link to={`/platform/${p.slug}/`}>
          <button>{p.homepageButtonTitle || 'Learn more'}</button>
        </Link>
      </div>
    ));
  }
  return (
    <Wrapper>
      <Container>
        <Inner>
          <SectionHeading
            heading={'We can help shape—and share—yours.'}
            noUnderline
          />
          <div className="features row">{renderPlatformFeatures()}</div>
        </Inner>
      </Container>
    </Wrapper>
  );
}

HomePlatform.propTypes = homePlatformTypes;
