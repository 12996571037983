import React, { Fragment } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import map from 'lodash/map';

import { homeClientBlockTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import SectionHeading from './SectionHeading';

const Wrapper = styled.div`
  margin-top: 8rem;
  margin-bottom: 12rem;
`;

const ClientsContainer = styled.div`
  width: 90rem;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 4rem;
  margin-bottom: 8rem;
`;
const PartnersContainer = styled.div`
  width: 60rem;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ClientLogo = styled.img`
  max-width: 100%;
  align-self: center;
  padding-bottom: 2.4rem;
`;

const Partner = styled.div`
  text-align: center;
  * {
    text-align: center;
  }
`;

const PartnerImg = styled(Img)`
  width: 100%;
  max-width: 300px;
  margin: auto;
  margin-bottom: 2rem;
`;

export default function HomeClients({ clients, partners }) {
  function renderClients() {
    return map(clients, (c) => (
      <ClientLogo key={c.url} src={c.url} alt="Client logo" />
    ));
  }
  function renderPartners() {
    return map(partners, (p) => (
      <Partner key={p.id}>
        <div className="image">
          <PartnerImg fluid={p.image.fluid} />
        </div>
        <p>{p.body}</p>
      </Partner>
    ));
  }
  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <SectionHeading heading="Our Partners in Prose" noUnderline />
            <ClientsContainer>{renderClients()}</ClientsContainer>
            {/* <SectionHeading heading="Our Clients" noUnderline />*/}
            {/* <PartnersContainer>{renderPartners()}</PartnersContainer> */}
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}

HomeClients.propTypes = homeClientBlockTypes;
