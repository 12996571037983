/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import _map from 'lodash/map';

import { Container, Inner } from './Elements';
import SectionHeading from './SectionHeading';
import { colors } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  margin-bottom: 10rem;
  ${Inner} {
    display: flex;
    flex-wrap: wrap;
  }
`;
const TWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const T = styled.div`
  width: 49%;
  margin-right: 2%;
  margin-bottom: 4rem;
  border-radius: 2rem;
  border: 0.2rem solid ${colors.blue};
  padding: 3rem 3.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  ${mq.tabletSmall`
    width: 100%;
  `}

  .quote {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-50%);
    background: ${colors.blue};
    font-size: 4rem;
    height: 4rem;
    width: 4rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    pointer-events: none;
    span {
      position: relative;
      transform: translateY(14%);
      pointer-events: none;
    }
  }

  strong {
    font-size: 2rem;
  }

  .body {
    margin-bottom: 2.4rem;
  }
  &:nth-child(even) {
    margin-right: 0;
  }
`;

export default function HomeTestimonials({ testimonials }) {
  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <SectionHeading heading="The cat’s meow" />
            <TWrap>
              {_map(testimonials, (t) => (
                <T key={t.id}>
                  <div className="quote">
                    <span>&quot;</span>
                  </div>
                  <p className="body">{t.body}</p>
                  <p className="name">
                    <strong>{t.name}</strong>
                    <br />
                    {t.title}
                  </p>
                </T>
              ))}
            </TWrap>
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}
