import React, { Fragment } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { homeHeroTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import { font } from '../consts/style';
import { Link } from 'gatsby';
import mq from '../style/mq';

const Wrapper = styled.div`
  margin-bottom: 6rem;
  overflow-x: hidden;
  ${Inner} {
    min-height: calc(100vh - 10rem);
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .image-wrap {
      width: 60rem;
      max-width: 100%;
      flex-shrink: 0;
    }
    .content-wrap {
      padding-left: 6rem;

      h1 {
        ${font.displaySmall};
      }
      h3 {
        ${font.h4}
        margin-bottom: 4rem;
      }
      a {
        button {
          ${font.button}
        }
      }
    }

    ${mq.tablet`
      flex-direction: column;

      .content-wrap {
        padding-left: 0;
        h1 {
          ${font.displaySmall};
          margin-bottom: 2rem;
        }
      }

      * {
        text-align: center;
      }
    `}
  }
`;

const HeroImage = styled(Img)`
  width: 100%;
  max-height: 100%;
`;

const TextWrapper = styled.div`
  ${Inner} {
    width: 84rem;
    max-width: 100%;
    text-align: center;
    h1 {
      margin-bottom: 2.4rem;
    }
    h4 {
      width: 46rem;
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 6rem;
    }

    .dividers {
      position: relative;
      &:before {
        content: '';
        width: 20rem;
        height: 0.05rem;
        background: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-3.2rem);
      }
      &:after {
        content: '';
        width: 20rem;
        height: 0.05rem;
        background: white;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) translateY(3.2rem);
      }
    }
  }
`;

export default function HomeHero({
  heroTitle,
  heroImage,
  heroSubtitle,
  heroButtonText,
  aboutHeading,
  aboutSubheading,
  aboutCopy,
}) {
  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <div className="content-wrap">
              <h1>There’s always room for narrative.</h1>
            </div>
            <div className="image-wrap">
              <HeroImage fluid={heroImage.fluid} />
            </div>
          </Inner>
        </Container>
      </Wrapper>
      {/* <TextWrapper>
        <Container>
          <Inner>
            <h1>{aboutHeading}</h1>
            <h4>{aboutSubheading}</h4>
            <div
              className="dividers"
              dangerouslySetInnerHTML={{ __html: aboutCopy }}
            />
          </Inner>
        </Container>
      </TextWrapper> */}
    </Fragment>
  );
}

HomeHero.propTypes = homeHeroTypes;
